<app-header-style-six></app-header-style-six>

<app-homeeleven-main-banner></app-homeeleven-main-banner>

<!-- <app-services></app-services> -->
<div class="contact-info-area pt-90 pb-90">
    <div class="contact-info-area pt-100 pb-70">
        <div class="boxes">
            <div class="row res-box justify-content-center">
                <div class="col-lg-2 box">
                    <a href="https://cenaze.se" target="_blank">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/img/isvecPhotos/IconCenazeFonu.png" alt="">

                            </div>
                            <h3>Cenaze Fonu</h3>
                        </div>
                    </a>
                </div>
                <div [routerLink]="['/hac-kayit', this.hajj]" class="col-lg-2 box">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconHac.png" alt="">


                        </div>
                        <h3>Hac Kayıtları</h3>
                    </div>
                </div>
                <div [routerLink]="['/umre-kayit', this.umrah]" class="col-lg-2 col-md-6 col-sm-6 box">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconUmre.png" alt="">

                        </div>
                        <h3>Umre Kayıtları</h3>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 box">
                    <div [routerLink]="['/kurban', this.victim]" class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconKurban.png" alt="">
                        </div>
                        <br>
                        <h3>Kurban</h3>
                    </div>
                </div>
                <div [routerLink]="['/zekat', this.alms]" class="col-lg-2 col-md-6 col-sm-6 box">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconZekat.png" alt="">
                        </div>
                        <h3>Zekat</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6 box">
                    <a href="https://hafiz.musdav.org.tr/" target="_blank">
                        <div class="contact-info-box mb-30">
                            <div class="icon">
                                <img src="assets/img/isvecPhotos/IconBirHafiz.png" alt="">

                            </div>
                            <h3>Bir Hafız Yetiştiriyorum</h3>
                        </div>
                    </a>
                </div>


                <div routerLink="/camiler" class="col-lg-2 col-md-6 col-sm-6 box">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconCami.png" alt="">

                        </div>
                        <h3>Camiler</h3>
                    </div>
                </div>
                <div [routerLink]="[clergy[0]?.frontMenuRecords[0]?.slug + '/1']"
                    class="col-lg-2 col-md-6 col-sm-6 box">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <img src="assets/img/isvecPhotos/IconGorevli.png" alt="">
                        </div>
                        <h3>Din Görevlileri</h3>
                    </div>
                </div>
                <!-- <div class="col-lg-2 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <i class='bx bxs-volume-full'></i>
                        </div>
                        <h3>Hutbeler</h3>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="contact-info-box mb-30">
                        <div class="icon">
                            <i class='bx bxs-image'></i>
                        </div>
                        <h3>Galeri</h3>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <!-- <div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
    </div> -->

    <!-- <app-homeeight-about></app-homeeight-about> -->

    <!-- <app-homeeight-courses></app-homeeight-courses> -->

    <!-- <div class="partner-area bg-color ptb-100">
        <div class="container">
            <app-partner-style-one></app-partner-style-one>
        </div>
    </div> -->

    <div class="courses-area pt-100 pb-70">
        <div class="container">
            <div class="courses-list-tab">
                <ul class="nav-tabset">
                    <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                        <span (click)="switchTab($event, 'tab1')">Haberler</span>
                    </li>
                    <!-- <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">Duyurular</span>
                </li> -->
                    <!-- <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                        <span class="active" (click)="switchTab($event, 'tab3')">Camiler</span>
                    </li> -->


                    <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                        <span (click)="switchTab($event, 'tab4')">Vakıf Yayınları</span>
                    </li>
                </ul>
                <div class="tabs-container">
                    <!-- <div class="pane" id="tab1" *ngIf="currentTab === 'tab3'">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/fittjaCami.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Fittja Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/flemingsbergCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Flemingsberg Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/goteborgCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Göteborg Diyanet Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/handenCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Handen Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/jonkopingCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Jönköping Diyanet Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/malmoCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Malmö Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/rinkebyMevlanaCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Rinkeby Mevlana Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-courses-item mb-30">
                                    <div class="courses-image">
                                        <a class="d-block"><img src="assets/cf-imgs/camiler/tenstaCamii.jpeg"
                                                class="img-mosque" alt="image"></a>
                                    </div>

                                    <div class="courses-content">
                                        <h3><a class="d-inline-block">Tensta Camii</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                        <div class="row">
                            <div class="instructor-area pt-100 pb-70">
                                <div class="container">
                                    <div style="text-align: start;" class="section-title">
                                        <div class="container">
                                            <div class="row tab-pane">
                                                <div class="col-lg-12 col-md-12 col-sm-12 tab-pane">
                                                    <div class="faq-accordion faq-accordion-style-two">
                                                        <div class="accordion-item" *ngFor="let item of accordionItems">
                                                            <div class="accordion-header"
                                                                (click)="toggleAccordionItem(item)">
                                                                {{ item.title }}
                                                                <i class='bx'
                                                                    [ngClass]="{'bx-chevron-down': !item.open, 'bx-chevron-up': item.open}"></i>
                                                            </div>
                                                            <div class="accordion-content" [hidden]="!item.open">
                                                                {{ item.content }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> -->
                    <div class="pane" id="tab3" *ngIf="currentTab === 'tab1'">
                        <app-blog></app-blog>
                    </div>
                    <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                        <div style="margin-top: -74px;" class="row publish">
                            <div class="instructor-area pt-100 pb-70">
                                <div class="container">
                                    <div style="text-align: start;" class="section-title">
                                        <div class="container">
                                            <div class="row tab-pane">
                                                <div class="col-lg-12 col-md-12 tab-pane">
                                                    <div class="faq-accordion faq-accordion-style-two">
                                                        <div class="accordion-item" *ngFor="let list of list">
                                                            <div class="accordion-header"
                                                                (click)="toggleAccordionItem(list)">
                                                                {{ list?.name }}
                                                                <i class='bx'
                                                                    [ngClass]="{'bx-chevron-down': !list.open, 'bx-chevron-up': list.open}"></i>
                                                            </div>
                                                            <div class="accordion-content" [hidden]="!list.open">
                                                                <img class="img-publish" src="{{photoUrl+list?.image}}"
                                                                    alt="">
                                                                <div style="display:flex; flex-direction:column;">
                                                                    <div class="publish-writer">
                                                                        <div>{{ list?.subtitle }} </div>
                                                                        <div>{{list?.description}}</div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-header-style-two></app-header-style-two>

    <!-- <app-funfacts-feedback></app-funfacts-feedback> -->

    <!-- <section class="faq-area bg-f8e8e9 pb-100">
    <app-faq></app-faq>
    <div class="divider bg-f9f9f9"></div>
</section> -->

    <!-- <app-homeeight-events></app-homeeight-events> -->

    <!-- <app-premium-access></app-premium-access> -->