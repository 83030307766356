<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="courses-list-tab mosque">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">Camiler</span>
                </li>
                <!-- <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">Temel İlkeler</span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span class="active" (click)="switchTab($event, 'tab3')">Tarihçe</span>
                </li>


                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">Vakıf Yönetim Kurulu Üyeleri</span>
                </li> -->
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="instructor-area pt-100 pb-70">
                            <div class="container">
                                <div class="section-title">
                                    <h2>Tarihçe</h2>
                                    <p> İsveç Diyanet Vakfı, tüzüğünün 3. maddesinde de belirlenen amaç ve faaliyetleri
                                        gerçekleştirmek üzere 1984 yılında kurulmuş ve İsveç mevzuatına göre kuruluşunu
                                        tamamlayarak hükmi şahsiyet kazanmıştır.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="instructor-area pt-100 pb-70">
                            <div class="container">
                                <div style="text-align: start;" class="section-title">
                                    <h2>Temel İlkeler</h2>
                                    <ul>
                                        <li>İsveç’te yaşayan müslüman Türklerin ve diğer müslümanların dini görevlerini
                                            yerine
                                            getirebilmeleri için yeni imkanlar hazırlamak, mevcut imkanları korumak ve
                                            geliştirmek,
                                            müslüman gençlerin dini ve kültürel eğitimlerini sağlamak üzere öğrenci
                                            yurtları veya
                                            okullar açmak.</li>
                                        <li>İsveç’te yaşayan Türk toplumunun ve diğer müslümanların dini ve sosyal
                                            ihtiyaçlarının
                                            karşılanabilmesi için, gerekli maddi imkanların sağlanması amacına yönelik
                                            çalışmalar
                                            yapmak, müslümanların yiyecek ihtiyaçlarının dini usullere göre
                                            giderilmesine yardımcı
                                            olmak üzere organizasyonlarda bulunmak.</li>
                                        <li>sveç’te bulunan Türk toplumunun ve diğer müslümanların manevi bakımdan
                                            gelişmesi için
                                            imkanlar sağlamak, bu konuda gereken tedbirlerin alınmasına yardımcı olmak.
                                        </li>
                                        <li>slam Dinini tanımak isteyenlere bu dinin gerçek yapısı, mahiyeti,
                                            özellikleri konusunda
                                            bilgi vermek, gerekli kitap ve benzeri kaynakların temininden yardımcı
                                            olmak.</li>
                                        <li>
                                            İsveç’teki bütün müslümanların İslam dini konusunda bilgi edinmeleri için
                                            gerekli
                                            eğitim, öğretim ve kültürel konularda tedbirler alınmasına yardımcı olmak,
                                            gerekirse bu
                                            ülkedeki dini, sosyal ve idari kuruluşlarla işbirliği yapmak.
                                        </li>
                                        <li>
                                            İsveç’te yaşayan müslümanlara dini, kültürel, ilmi, sosyal ve sanatsal
                                            hizmet
                                            alanlarında hizmetler vermek, gerektiğinde gençlere yönelik sportif
                                            çalışmalar için
                                            imkanlar hazırlamak.
                                        </li>
                                        <li>Müslüman olmak isteyenlere İslam dini hakkında bilgi vermek ve yardım etmek.
                                        </li>
                                        <li>Evlerinde aileleri ile problemleri olan çocukların ıslahı için çocuk
                                            ıslahevleri açmak.
                                        </li>
                                        <li> Özürlü ve kusurlu müslüman çocuklarının eğitimi için çalışmalar yapmak.
                                        </li>
                                        <li>Müslümanların inanç, ibadet ve ahlaki değerlerini korumalarına yardımcı
                                            olmak üzere;
                                            yazılı, sesli, görüntülü neşriyatta bulunmak.</li>
                                        <li>Müslümanların dini bayramlarını gereğince kutlamalarına yardımcı olmak.</li>
                                        <li>Müslümanların yoğun olduğu iş yerlerinde, ibadetlerini yerine getirmeleri
                                            için
                                            gerektiğinde onlara yardımcı olmak.</li>
                                        <li>Yetenekli gençlere yardım ederek, bunların yüksek öğrenim, master veya
                                            doktora
                                            yapmalarına imkanlar hazırlamak.</li>
                                        <li>
                                            Vakıf gayesi için kullanılabilecek gayrimenkullere sahip olmak, "Türkiye
                                            Diyanet Vakfı"
                                            ile işbirliği yapmak, "Dünya İslam Birliği" ve diğer İslami kuruluşlarla
                                            gerektiğinde
                                            temas kurmak, Avrupada kurulu Diyanet Vakıf ve Birliklerince teşekkül
                                            ettirilecek bir
                                            üst kuruluşa üye olmak.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab1'">
                    <!-- <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/fittjaCami.jpeg"
                                        class="img-mosque"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Fittja Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/flemingsbergCamii.jpeg"
                                        class="img-mosque"    alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Flemingsberg Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/goteborgCamii.jpeg"
                                        class="img-mosque"    alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Göteborg Diyanet Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/handenCamii.jpeg"
                                        class="img-mosque"     alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Handen Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/jonkopingCamii.jpeg"
                                        class="img-mosque"        alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Jönköping Diyanet Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/malmoCamii.jpeg"
                                        class="img-mosque"  alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Malmö Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/rinkebyMevlanaCamii.jpeg"
                                        class="img-mosque"  alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Rinkeby Mevlana Camii</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/camiler/tenstaCamii.jpeg"
                                        class="img-mosque"  alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3><a class="d-inline-block">Tensta Camii</a></h3>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12" *ngFor="let list of list">
                                    <div class="single-courses-list-box mb-30">
                                        <div class="box-item">
                                            <div class="courses-image">
                                                <div class="image bg-1">
                                                    <img src={{photoUrl+list?.image}} class="img-mosque"
                                                        alt="image">


                                                </div>
                                            </div>
                                            <div class="courses-desc">
                                                <div class="courses-content" >

                                                    <h3>{{list?.name}}</h3>
                                                    <p><span><b>DİN GÖREVLİLERİ:</b> {{list?.imamFullName}}</span>
                                                    </p>
                                                  <p [innerHTML]=" list?.explanationAboutMosque"></p>

                                                </div>
                                                <div class="courses-box-footer">
                                                    <ul>

                                                        <!-- <li class="students-number">
                                                            <i class='bx bx-user'></i> Tegelbruksvägen 10 145 53
                                                            Norsborg/Stockholm
                                                        </li>
                                                        <li class="courses-lesson">
                                                            <i class='bx bx-book-open'></i> +46 8 531 773 94
                                                        </li> -->
                                                        <li><i class='bx bx-world'></i> <a>
                                                               {{list?.address}}</a></li>
                                                        <li><i class='bx bx-phone'></i> <a href="tel:{{list?.phoneNumber}}">{{list?.phoneNumber}}</a></li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                <!-- <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/fazliarslan.png" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Fazlı Arslan</a></h3>
                                    <p class="board-paragraph">İsveç Diyanet Vakfı Yönetim Kurulu Başkanı</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/caferKilic.png" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Cafer Kılıç</a></h3>
                                    <p class="board-paragraph">İsveç Diyanet Vakfı Yönetim Kurulu Başkan Yardımcısı</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/ismahanAyranci.png" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">İsmahan Ayrancı</a></h3>
                                    <p class="board-paragraph">Sekreter</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/dgSerdarBukme.jpeg" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Serdar Bükme</a></h3>
                                    <p class="board-paragraph">Muhasip</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/dgMetinTekin.jpeg" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Metin Tekin</a></h3>
                                    <p class="board-paragraph">Üye</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/dg/dgHanifeCakmak.jpeg"
                                            class="img-board" alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Hanife Çakmak</a></h3>
                                    <p class="board-paragraph">Yedek Yönetim Kurulu Üyesi</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-board-item mb-30">
                                <div class="courses-image">
                                    <a class="d-block"><img src="assets/cf-imgs/isvecDiyanetLogo.png" class="img-board"
                                            alt="image"></a>
                                </div>

                                <div class="courses-content">
                                    <h3 class="board-title"><a class="d-inline-block">Yusuf Tuncer</a></h3>

                                    <p class="board-paragraph">Yedek Yönetim Kurulu Üyesi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>